@font-face {
  font-family: 'Poppins-rg';
  src: local('Poppins-Regular'),
    url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-md';
  src: local('Poppins-Medium'),
    url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-lt';
  src: local('Poppins-Light'),
    url(./assets/fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-rg';
  src: local('Quicksand-Regular'),
    url(./assets/fonts/Quicksand-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-bold';
  src: local('Quicksand-Bold'),
    url(./assets/fonts/Quicksand-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-semiBold';
  src: local('Quicksand-semiBold'),
    url(./assets/fonts/Quicksand-SemiBold.ttf) format('truetype');
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
