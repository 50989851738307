@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer), (max-width: variables.$max_width_medium_computer) {
  .btn-danger,
  .btn-success {
    margin-inline: 50px;
    border-radius: 0.25rem;
    font: 20px base.$primary-font-rg;
  }

  .delete-icon {
    cursor: pointer;
    border-radius: 50%;
    max-width: 0.85rem;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
