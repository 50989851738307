@use 'base';

// page container
@mixin page-screen {
  background: base.$color-white;
  border: 1px solid base.$color-lightgrey-border;
  border-radius: 0.5rem;
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  padding: 1rem;
}

// page main title
@mixin page-title {
  font: 30px base.$secondary-font-sb;
  color: base.$color-darkgrey;
  margin-top: 1.5rem;
}

@mixin page-title-container {
  margin-left: 1.5rem;
}

@mixin border-title {
  border-image: linear-gradient(
      to right,
      base.$color-primary-green,
      base.$color-white
    )
    1;
  width: 80%;
  border-bottom: 2px solid;
  display: inline-block;
}

// auth screens
@mixin auth-screen {
  background-color: base.$color-white;
  flex-grow: 1;
}

@mixin auth-container {
  margin: 4rem auto;
  padding: 40px;
  max-width: 550px;
  background-color: base.$color-lightgrey-bg;
  border: 1px solid base.$color-lightgrey-border;
  border-radius: 5px;
}

@mixin auth-logo {
  width: 100%;
  max-width: 320px;
  padding-bottom: 0px;
  height: auto;
}

@mixin auth-logo-container {
  display: block;
  text-align: center;
}

@mixin form-label {
  font: 20px base.$primary-font-rg;
}

@mixin input-form {
  font-family: base.$primary-font-lt;
  border-radius: 0.5rem;
}

@mixin input-form-focus {
  border-color: base.$color-primary-green !important;
  box-shadow: 0 0 5px base.$color-primary-green !important;
  outline: base.$color-primary-green !important;
}

@mixin auth-button {
  font-family: base.$primary-font-rg !important;
  color: base.$color-white !important;
  font-size: 20px !important;
  background-color: base.$color-primary-green !important;
  border-color: base.$color-primary-green !important;
  border-radius: 0.5rem !important;
  box-shadow: none !important;
  min-width: 20% !important;
  margin: 0px !important;
}

@mixin auth-button-hover {
  background-color: base.$color-green-hover !important;
}

@mixin auth-button-disable {
  background-color: base.$color-green-hover !important;
  opacity: 0.65;
}

@mixin go-back-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15%;
}

@mixin go-back-text {
  font-family: base.$primary-font-md;
  color: base.$color-primary-green;
  font-size: smaller;
}

@mixin go-back-text-hover {
  color: base.$color-darkgrey;
}

@mixin input-error {
  color: base.$color-error;
  margin-bottom: 10px;
}

@mixin modal-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

@mixin modal-scrollbar-track {
  border-radius: 10px;
  background: base.$color-lightgrey-hover;
}

@mixin modal-scrollbar-thumb {
  border-radius: 10px;
  background: base.$color-primary-green;
}

@mixin modal-scrollbar-thumb-hover {
  background: base.$color-green-hover;
}

@mixin modal-scrollbar-thumb-active {
  background: base.$color-green-hover;
}
