@use '../../scss/base';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .auth-footer {
    background-color: base.$color-white;
  }

  .legals,
  .privacy-policy {
    font-family: base.$primary-font-rg;
    font-size: 16px;
    color: base.$color-lightgrey-2;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
  footer {
    display: flex;
    flex-direction: column;
  }
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .legals,
  .privacy-policy {
    font-size: 15px;
  }
}
