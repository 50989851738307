@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .privacy-policy-ctr {
    background: base.$color-white;
    border: 1px solid base.$color-lightgrey-border;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    margin: 1rem 5rem;
    padding: 3rem;
  }

  .privacy-title {
    font-family: base.$secondary-font-sb;
    font-size: 25px;
    font-weight: bold;
    color: base.$color-darkgrey;
    text-transform: uppercase;
  }

  .privacy-border-image {
    border-image: linear-gradient(
        to right,
        base.$color-primary-green,
        base.$color-white
      )
      1;
    width: 80%;
    border-bottom: 2px solid;
    display: inline-block;
    margin-bottom: 1rem;
  }

  .privacy-content {
    font-family: base.$primary-font-rg;
    text-align: justify;
    white-space: pre-line;
    &.bd {
      font-weight: bold;
    }
    padding: 1rem 0;
  }

  .privacy-go-back {
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;
  }

  .privacy-go-back-txt {
    font-family: base.$primary-font-md;
    font-size: 16px;
    color: base.$color-primary-green;
    &:hover {
      color: base.$color-darkgrey;
    }
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
  .privacy-policy-ctr {
    padding: 2rem;
    margin: 1rem;
  }
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .privacy-policy-ctr {
    margin: 1rem 0.5rem;
    padding: 0.5rem;
  }
}
