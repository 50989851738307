@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .dashboard-filter-title {
    font-family: base.$primary-font-rg;
    font-weight: bold;
    margin-right: 2rem;
  }

  .validate-checkedId {
    font: 17px base.$primary-font-md !important;
    color: base.$color-white !important;
    border-radius: 0.75rem !important;
    border-color: base.$color-green-tag !important;
    background-color: base.$color-green-tag !important;
    box-shadow: none !important;
    &:hover {
      background-color: base.$color-green-hover !important;
      border-color: base.$color-green-hover !important;
    }
  }

  .filter-btn {
    font: 17px base.$primary-font-md !important;
    border-radius: 13px !important;
    border-color: base.$color-grey-tag !important;
    background-color: base.$color-grey-tag !important;
    box-shadow: none !important;
    &:hover {
      background-color: base.$color-lightgrey-hover !important;
      border-color: base.$color-lightgrey-hover !important;
      box-shadow: none !important;
    }
  }

  .filtered-btn {
    font: 17px base.$primary-font-md !important;
    color: base.$color-white !important;
    border-radius: 13px !important;
    border-color: base.$color-primary-green !important;
    background-color: base.$color-primary-green !important;
    box-shadow: none !important;
    &:hover {
      background-color: base.$color-green-hover !important;
      border-color: base.$color-green-hover !important;
      box-shadow: none !important;
    }
  }

  .filter-icon-ctr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    &:hover {
      background-color: base.$color-lightgrey-hover !important;
    }
  }

  .filtered-icon-ctr {
    display: flex;
    color: base.$color-primary-green;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    &:hover {
      color: base.$color-white;
      background-color: base.$color-green-hover !important;
    }
  }

  .filter-label {
    color: base.$color-darkgrey;
  }

  .filtered-label {
    color: base.$color-white;
  }

  .filters-list {
    margin-top: 10px;
    max-height: 220px;
    overflow: auto;
  }

  .filters-list::-webkit-scrollbar {
    @include mixin.modal-scrollbar;
  }
  .filters-list::-webkit-scrollbar-track {
    @include mixin.modal-scrollbar-track;
  }
  .filters-list::-webkit-scrollbar-thumb {
    @include mixin.modal-scrollbar-thumb;
    &:hover {
      @include mixin.modal-scrollbar-thumb-hover;
    }
    &:active {
      @include mixin.modal-scrollbar-thumb-active;
    }
  }

  .filters-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }

  .MuiFormGroup-root > label {
    margin-bottom: 0;
  }

  .dashboard-filters-title {
    font-family: base.$primary-font-rg;
    font-size: 20px;
    font-weight: bold;
    color: base.$color-darkgrey;
    margin-bottom: 1rem;
  }

  .dashboard-filters-searchbar {
    font-family: base.$primary-font-rg;
    font-size: 20px;
    margin-bottom: 1rem;
    width: 100%;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
  .dashboard-filter-title {
    margin-right: 0;
  }
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
