@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .analysisReport-ctr {
    margin-bottom: 0.5rem;
  }

  .analysisReport-scr {
    @include mixin.page-screen;
  }

  .selected-product-name {
    margin: 1.5rem 1rem 0 1.5rem;
  }

  .selected-product {
    font-family: base.$primary-font-rg;
    font-weight: bold;
    margin-right: 1rem;
  }

  .datePicker-ctr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0 0 1.5rem;
  }

  .no-analyses-msg {
    display: flex;
    justify-content: center;
    margin: 5rem 0 5rem 0;
    font-family: base.$primary-font-rg;
    font-size: 1.25rem;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.hidden-btn {
    color: unset;
    box-shadow: unset;
    background-color: unset;
    padding: unset;
  }

  .footer-generic-ctr {
    display: flex;
    align-items: center;
    margin: 1rem 1.5rem 0 1.5rem;

    &::before {
      flex: 1;
      content: '';
    }
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .analysisReport-scr {
    padding: 0.5rem;
  }

  .selected-product-name {
    margin: 1.5rem 0 0 0;
  }

  .selected-product {
    font-family: base.$primary-font-rg;
    font-weight: bold;
    display: flex;
    margin-right: 0;
  }

  .datePicker-ctr {
    flex-direction: column;
    justify-content: space-between;
    margin: 1.5rem 0 0 0;
  }

  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin: 1rem 0 1rem 0;
  }
}
