@use '../scss/base';
@use '../scss/mixin';
@use '../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer), (max-width: variables.$max_width_medium_computer) {
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.disabled-btn {
    font-family: base.$primary-font-md;
    font-size: 17px;
    border-radius: 13px;
    border-color: base.$color-primary-green;
    background-color: base.$color-primary-green;
    box-shadow: none;
    opacity: 0.65;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
