@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer), (max-width: variables.$max_width_medium_computer) {
  .shortcut-icon:hover > path{
    fill: base.$color-green-hover;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-text.select-button {
    display: flex;
    font-family: base.$primary-font-rg !important;
    font-size: 20px !important;
    background-color: base.$color-lightgrey !important;;
    border-color: base.$color-primary-green !important;
    border-radius: 0.5rem !important;
    min-width: 20% !important;
  }

  .select-button:hover {
    background-color: base.$color-green-hover !important;
    border-color: base.$color-green-hover !important;
  }

  .no-app-shortcut {
    font-family: base.$primary-font-rg;
    font-size: 14px;
    color: base.$color-darkgrey;
    cursor: default;
    padding: 0 1rem 1rem 1rem;
  }
}

/* Mobile */
@media only screen and (max-width: variables.$max_width_mobile) {
  .shortcut-dropdown {
    display: none;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-text.select-button {
    margin-top: 1rem;
  }
}
