@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer), (max-width: variables.$max_width_medium_computer) {
  .select-ctr {
    border-radius: 13px;
    border: none;
    background-color: base.$color-grey-tag;
    box-shadow: none;
    padding: 6px;
  }

  select:focus-visible {
    outline: none;
  }

  .select-ctr select,
  .select-ctr select:active,
  .select-ctr select:focus,
  .select-ctr select:hover {
    border: none;
  }

  .value-selected,
  .value-options {
    font: 17px base.$primary-font-md;
    background-color: base.$color-grey-tag;
    cursor: pointer;
    text-align: center;
  }
}
