@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer), (max-width: variables.$max_width_medium_computer) {
  .icon-title-chart-drawer {
    color: base.$color-primary-green;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}