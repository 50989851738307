@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer), (max-width: variables.$max_width_medium_computer) {
  .list-datasets-ctn {
    display: flex;
    flex-wrap: wrap; 
    gap: 0.5em;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
