@use './scss/variables';
@use './scss/base';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .App {
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
    min-height: 100vh;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: base.$color-primary-green base.$color-white;
  }

  ul, li {
    list-style-type: none;
  }

  .table td {
    border: none;
  }


  td {
    white-space: nowrap;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
  td {
    white-space: nowrap;
  }
}
