@use '../../scss/base';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .icon_ctr {
    display: flex;
    align-items: center;
  }

  .checkbox_icon,
  .action_icon {
    color: base.$color-darkgrey !important;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    transition: flex 0.3s;
    overflow: hidden;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 15px;
    }
  }

  .action_icon {
    padding: 10px;
  }
  
  .dropdown_content {
    position: absolute;
    margin-top: 3rem;
  }
}
