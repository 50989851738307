@use '../../scss/base';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .tooltip-container {
    .trigger {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .tooltip {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      position: absolute;
      z-index: 1;
      min-width: 20rem;
      padding: 0.5rem 2rem;
      margin-top: 1rem;
      right: 15%;
      background-color: base.$color-white;
      background-clip: padding-box;
      border-radius: 0.25rem;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1rem;
        height: 100%;
        background-color: base.$color-error;
        border-radius: 0.25rem 0 0 0.25rem;
      }
    }

    &:hover .tooltip {
      opacity: 1;
      visibility: visible;
    }
  }

  .tooltip_content {
    font-family: base.$primary-font-rg;
    color: base.$color-lightgrey-2;
    font-size: 1rem;
  }
}
