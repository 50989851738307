@use '../scss/base';
@use '../scss/mixin';
@use '../scss/variables';

@media only screen and (min-width: variables.$min_width_computer), (max-width: variables.$max_width_medium_computer) {
  .swal2-container.swal2-center.swal2-backdrop-show {
    z-index: 1400 !important;
  }

  .swal2-confirm {
    font: 17px base.$primary-font-md;
    border-radius: 0.5rem;
    border-color: base.$color-green-tag;
    background-color: base.$color-green-tag;
  }

  .swal2-confirm:hover {
    background-color: base.$color-green-hover !important;
    border-color: base.$color-green-hover !important;
  }

  .swal2-confirm:focus {
    outline: none;
    box-shadow: none;
    border-color: base.$color-green-tag;
    background-color: base.$color-green-tag;
  }
}
