@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$max_width_mobile), (max-width: variables.$max_width_medium_computer) {
  .application-item {
    display: flex;
    flex-direction: column;
    border-radius: 0.75rem;
    margin-top: 10px;
    margin-right: 30px;
    padding-top: 20px;
    height: 155px;
    width: 165px;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: none;
  }

  .application-icon {
    color: base.$color-white;
    margin: 0.5rem 1.75rem 0 1.75rem;
  }

  .application-item-title {
    font-family: base.$primary-font-rg;
    font-size: 20px;
    color: base.$color-white;
    margin: 0.5rem 1rem 1.5rem 1rem;
  }
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .application-item {
    margin-right: 0px;
  }
}

