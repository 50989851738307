@use '../../scss/base';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .select_product_ctr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    font-family: base.$primary-font-rg;
    font-size: 18px;
    color: base.$color-darkgrey;
    padding: 1.5rem;
  }

  .selected_product {
    font-weight: 900;
  }

  .product_element {
    font-family: base.$primary-font-rg !important;
    font-size: 20px !important;
    background-color: base.$color-lightgrey !important;
    border-radius: 0.5rem !important;
    margin: 0 0rem 1rem 0rem !important;
    padding: 1rem 1rem 1rem 1rem !important;
    box-shadow: none !important;
    &:hover {
      background-color: base.$color-green-hover !important;
      border-color: base.$color-green-hover !important;
      color: base.$color-white !important;
      box-shadow: none !important;
    }
  }

  .select_product_btn {
    color: base.$color-white !important;
    font-family: base.$primary-font-md !important;
    font-size: 17px !important;
    border-radius: 0.75rem !important;
    border-color: base.$color-green-tag !important;
    background-color: base.$color-green-tag !important;
    box-shadow: none !important;
    &:hover {
      border-color: base.$color-green-hover !important;
      background-color: base.$color-green-hover !important;
    }
  }

  .modify_product_btn {
    color: base.$color-darkgrey !important;
    font-family: base.$primary-font-md !important;
    font-size: 17px !important;
    border-radius: 0.75rem !important;
    border-color: base.$color-lightgrey !important;
    background-color: base.$color-lightgrey !important;
    box-shadow: none !important;
    &:hover {
      border-color: base.$color-lightgrey-hover !important;
      background-color: base.$color-lightgrey-hover !important;
    }
  }
}

/* mobile */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .select_product_ctr {
    justify-content: center;
  }
}
