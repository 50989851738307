@use '../../scss/base';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .delete-tag {
    font: 17px base.$primary-font-md !important;
    color: base.$color-white !important;
    border-radius: 0.75rem !important;
    border-color: base.$color-red !important;
    background-color: base.$color-red !important;
    box-shadow: none !important;
    &:hover {
      background-color: base.$color-error !important;
      border-color: base.$color-error !important;
    }
  }

  .delete-warning {
    font-family: base.$primary-font-rg;
    font-size: 20px;
    text-align: center;
  }

  .tag_name {
    color: base.$color-primary-green;
    font-weight: bold;
  }

  .cancel {
    font: 17px base.$primary-font-md !important;
    color: base.$color-white !important;
    border-radius: 0.75rem !important;
    border-color: base.$color-lightgrey-2 !important;
    background-color: base.$color-lightgrey-2 !important;
    box-shadow: none !important;
    &:hover {
      background-color: base.$color-lightgrey-hover !important;
      border-color: base.$color-lightgrey-hover !important;
    }
  }

  .delete-btn-ctr {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
