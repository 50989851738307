@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer), (max-width: variables.$max_width_medium_computer) {
  .home-container {
    background: base.$color-white;
    border: 1px solid base.$color-lightgrey-border;
    border-radius: 0.5rem;
    width: 80%;
    margin: auto;
    margin-top: 5rem;
  } 
}

/* Tablet */
@media only screen and (min-width: 550px), (max-width: variables.$max_width_mobile) {
  .home-container {
    padding: 0 0.5em 2em 0.5em;
  }
}

/* Mobile */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .home-container {
    padding: 0 0.5em 2em 0.5em;
  }
}
