@use '../../scss/variables';

@media all and (min-width: 480px), (max-width: 660px) {
  .main-progress {
    margin-top: 30px;
  }

  .progress-container {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .progress {
    text-align: center;
    height: 1rem;
  }

  /* small */
  @media only screen and (max-width: variables.$max_width_small_phone) {
    .progress-container {
      min-width: 300%;
    }

    .progress {
      width: 100%;
    }
  }
}
