@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .chart {
    height: 45vw;
    width: 75vw;
  }

  .chart-button {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    &:hover {
      background-color: base.$color-lightgrey-hover !important;
    }
  }

  .chart-option-button {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    &:hover {
      color: base.$color-green-hover !important;
      background-color: base.$color-lightgrey-hover !important;
    }
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
  .chart {
    height: 60vw;
    width: 75vw;
  }
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .chart {
    height: 70vw;
    width: 75vw;
  }
}
