@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer), (max-width: variables.$max_width_medium_computer) {
  .auth-screen {
    @include mixin.auth-screen;
  }

  .auth-container {
    @include mixin.auth-container;
  }

  .auth-container .reset-password {
    margin: 0 auto;
    max-width: 350px;
  }

  .auth-logo {
    @include mixin.auth-logo;
  }

  .auth-logo-container {
    @include mixin.auth-logo-container;
  }

  .reset-title-container {
    text-align: center;
  }

  .reset-title {
    font: 30px base.$primary-font-md;
    margin-top: 10%;
  }

  .reset-text-container {
    text-align: center;
    margin-top: 10%;
  }

  .reset-text {
    font: 16px base.$primary-font-rg;
  }

  .auth-button {
    @include mixin.auth-button;
  }

  .auth-button:hover:enabled {
    @include mixin.auth-button-hover;
  }

  .label-username {
    margin-top: 8%;
  }

  #username {
    @include mixin.input-form;
  }

  #username:focus {
    @include mixin.input-form-focus;
  }

  .go-back-container {
    @include mixin.go-back-container;
  }

  .go-back-text {
    @include mixin.go-back-text;
  }

  .go-back-text:hover {
    @include mixin.go-back-text-hover;
  }

  .input-error {
    @include mixin.input-error;
  }
}

/* Mobile */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .auth-container {
    padding: 2rem 1rem 2rem 1rem;
    margin: 3rem 1rem;
  }
}
