@use '../../scss/base';
@use '../../scss/mixin';

@media all and (min-width: 480px), (max-width: 660px) {
  .modal-title {
    font: 30px base.$secondary-font-sb;
    color: base.$color-darkgrey;
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
  }

  .modal-container {
    text-align: start;
  }

  .filters-list {
    margin-top: 10px;
    max-height: 220px;
    overflow: auto;
  }
}
