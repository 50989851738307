@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .navbar {
    background-color: base.$color-white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .nav {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .btn-primary.dropdown-toggle,
  .show > .btn-primary.dropdown-toggle,
  .show > .btn-primary.dropdown-toggle:focus {
    color: base.$color-darkgrey;
    -webkit-box-shadow: none !important;
    background-color: base.$color-white !important;
    border: none;
    box-shadow: none;
  }

  .btn-primary.dropdown-toggle::after {
    display: none;
  }

  .nav-profile,
  .nav-link {
    font-family: base.$primary-font-rg;
    font-size: 16px;
    color: base.$color-white;
    padding: 0 1rem;
  }

  .nav-menu-items {
    width: 100%;
  }

  .nav-user {
    padding-left: 0.25em;
    font-family: base.$primary-font-rg;
    font-size: 20px;
  }

  .profile-icon {
    background-color: base.$color-white;
    border: none;
    border-width: 5px;
    border-radius: 50%;
    margin-right: 0.5rem;
    width: 30px;
  }

  .icon-circle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .icon-circle:hover {
    color: base.$color-green-hover;
  }

  .icon-circle:hover > svg .cls-1 {
    fill: base.$color-green-hover !important;
  }

  .dropdown-toggle:after {
    display: none;
  }

  .btn-primary.dropdown-toggle,
  .show > .btn-primary.dropdown-toggle,
  .show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: none !important;
    background-color: base.$color-white !important;
    border: none;
    box-shadow: none;
  }

  .dropdown-menu a:active {
    color: base.$color-darkgrey;
  }

  .dropdown-menu {
    right: 0;
    left: auto;
  }

  .dropdown-menu.show {
    padding: 0.5rem 0.5rem;
    min-width: 300px;
  }

  .dropdown-user {
    font-family: base.$primary-font-rg;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
  }

  .dropdown-element {
    font-family: base.$primary-font-rg;
    color: base.$color-darkgrey;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dropdown-element.MuiListItem-gutters {
    padding-left: 1.5rem;
  }

  .dropdown-element:hover {
    background-color: base.$color-lightgrey-bg;
    border-radius: 0.25rem;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    display: none;
  }

  .dropdown-toggle.btn.btn-primary,
  .nav-profile.nav-link.dropdown-toggle.btn.btn-primary {
    padding: 0;
  }
  .logo {
    width: 100px;
  }
}

@media only screen and (max-width: variables.$max_width_mobile) {
  .navbar {
    padding: 0.5rem 0;
  }

  .profile-icon {
    display: none;
  }

  .nav-logout,
  .btn-primary.dropdown-toggle,
  .show > .btn-primary.dropdown-toggle,
  .show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: none !important;
    background-color: base.$color-white !important;
    border: none;
    box-shadow: none;
  }

  .nav {
    padding: 0;
  }

  .logout-link {
    display: none;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-text,
  .MuiButtonBase-root.MuiButton-root.MuiButton-text:hover {
    display: unset;
    background-color: base.$color-white;
  }

  .side-username {
    font-family: base.$primary-font-rg;
    color: base.$color-darkgrey;
    margin-left: 1rem;
  }

  .dropdown-item {
    white-space: unset;
  }
  .logo {
    width: 100px;
  }
}
