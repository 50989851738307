@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .side-item {
    display: flex;
    vertical-align: middle;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    &:hover {
      background-color: base.$color-lightgrey-bg;
      border-radius: 0.25rem;
    }
  }

  .side-label {
    font-family: base.$primary-font-rg;
    color: base.$color-darkgrey;
    padding-top: 2.5px;
  }

  .menu-icon {
    display: none;
  }
}

/* small */
@media only screen and (max-width: variables.$max_width_mobile) {
  .menu-icon {
    background-color: base.$color-white;
    border: none;
    color: base.$color-darkgrey;
    display: flex;
    justify-content: flex-start;
    &:after {
      display: none;
    }
  }

  .side-label {
    flex-shrink: 10;
  }

  .side-item.dropdown-item:active {
    background-color: base.$color-lightgrey-bg;
    white-space: pre-wrap;
  }

  .MuiButtonBase-root.MuiListItem-root.side-item.MuiListItem-gutters.MuiListItem-button {
    margin-bottom: 1rem;
  }
}
