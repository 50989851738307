@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer), (max-width: variables.$max_width_medium_computer) {
  .side-icon-container {
    border-radius: 0.2rem;
    margin-right: 1rem;
    height: 30px;
    width: 30px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
  }

  .side-icon {
    padding: 5px;
    margin-bottom: 1px;
  }
}
