@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .edit_tag_screen {
    @include mixin.page-screen;
  }

  .edit_btn_ctr {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .title_container {
    @include mixin.page-title-container;
  }

  button.back_btn {
    background-color: #e0e0e0;
    font-family: base.$primary-font-rg;
    font-size: 15px;
    margin: 0;
    border-radius: 0.75rem;
  }
}
