// base.scss

// primary fonts
$primary-font-lt: Poppins-lt;
$primary-font-rg: Poppins-rg;
$primary-font-md: Poppins-md;

// secondary fonts
$secondary-font-rg: Quicksand-rg;
$secondary-font-sb: Quicksand-semiBold;
$secondary-font-b: Quicksand-bold;

// white
$color-white: #ffffff;

// green
$color-primary-green: #33a237;
$color-green-hover: #2f9638;

// grey
$color-lightgrey: #f3f3f3;
$color-lightgrey-2: #707070;
$color-lightgrey-hover: #b9c0c4;
$color-darkgrey: #3a3a3a;
$color-lightgrey-bg: #f2f2f2;
$color-lightgrey-border: #e5e5e5;

// navbar
$color-icon-hover: rgba(47, 150, 56, 0.1);

// upload dropzone
$color-dropzone-text: #595959;

// tag button
$color-grey-tag: #e2e6ea;
$color-green-tag: #67b548;

// table
$color-table-header-bg: #e9ecef;
$color-table-item-hover: #f2f2f2;

// error
$color-error: #f20505;

// icon
$color-red: #e74c3c;
$color-grey: #595959;

// applications
$color-transmission: #33a237;
$color-transmission-hover: #2f9638;
$color-pig: #dd447b;
$color-pig-hover: #c63d6e;

// yellow
$color-yellow: #CEBA04;
