@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer), (max-width: variables.$max_width_medium_computer) {
  .analysisReport-table-container {
    margin: 2rem 1.5rem 0 1.5rem;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .title-container {
    margin-left: 0;
  }

  .analysisReport-table-container {
    margin: 2rem 0 0 0;
  }
}
