@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .language-picker-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
  }

  .dropdown-language-menu {
    right: 0;
    left: auto;
  }

  .dropdown-language-menu.show {
    padding: 0rem !important;
    min-width: 200px !important;
  }

  .dropdown-language {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: 16px base.$primary-font-rg;
    padding: 1rem;
    &:hover {
      background-color: base.$color-green-hover;
      color: base.$color-white;
    }
  }

  .dropdown-language-divider {
    margin: 0rem;
  }

  .language-dropdown {
    display: flex !important;
    background-color: base.$color-white;
    border-radius: 4rem;
  }

  .language-element {
    margin-left: 0.5rem;
  }

  .language-ctr {
    display: flex;
    align-items: center;
    &:hover {
      color: base.$color-green-hover;
    }
  }

  .language-flag {
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .language-txt {
    font-family: base.$primary-font-rg;
    font-size: 18px;
    text-transform: uppercase;
  }

  .nav-language {
    display: flex;
    align-items: center;
  }
}

/* Mobile */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .language-picker-container {
    margin-right: 0;
  }
}
