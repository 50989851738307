@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer), (max-width: variables.$max_width_medium_computer) {
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
  }

  .pagination-root {
    display: flex;
    justify-content: center;
  }

  .pagination-ctr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .pagination-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: base.$color-darkgrey;
    height: 25px;
    width: 25px;
    font-size: 18px;
  }

  .pagination-btn:hover,
  .pagination-btn:active {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: base.$color-lightgrey;
    height: 25px;
    width: 25px;
    border-radius: 50%;
  }

  .pagination-disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    font-size: 18px;
    pointer-events: none;
    opacity: 0.2;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: base.$color-primary-green !important;
    color: base.$color-white;
  }
}
