@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .tag-btn {
    font: 17px base.$primary-font-md !important;
    color: base.$color-white !important;
    border-radius: 0.75rem !important;
    border-color: base.$color-green-tag !important;
    background-color: base.$color-green-tag !important;
    box-shadow: none !important;
    &:hover {
      background-color: base.$color-green-hover !important;
      border-color: base.$color-green-hover !important;
    }
  }

  .search-bar-container {
    text-align: center;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .tag-list::-webkit-scrollbar {
    @include mixin.modal-scrollbar;
  }
  .tag-list::-webkit-scrollbar-track {
    @include mixin.modal-scrollbar-track;
  }
  .tag-list::-webkit-scrollbar-thumb {
    @include mixin.modal-scrollbar-thumb;
    &:hover {
      @include mixin.modal-scrollbar-thumb-hover;
    }
    &:active {
      @include mixin.modal-scrollbar-thumb-active;
    }
  }

  .search-bar {
    font: 20px base.$primary-font-rg;
    margin-bottom: 1rem;
    width: 100%;
  }

  .tag-list {
    margin-top: 10px;
    max-height: 220px;
    overflow: auto;
  }

  .modal-element {
    font-family: base.$primary-font-rg;
    font-size: 20px;
    color: base.$color-darkgrey;
    background-color: base.$color-lightgrey;
    border-radius: 0.5rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    min-height: 2.5rem;
    outline: none !important;
    &:hover {
      background-color: base.$color-lightgrey-hover;
    }
  }

  .add-btn {
    border-radius: 0.75rem !important;
    border-color: base.$color-green-tag !important;
    background-color: base.$color-green-tag !important;
    box-shadow: none !important;
    margin-top: 0.5rem !important;
    margin-left: 2rem !important;
    &:hover {
      border-color: base.$color-green-hover !important;
      background-color: base.$color-green-hover !important;
    }
  }

  .add-icon {
    align-items: center;
    color: base.$color-white;
    margin-right: 10px;
  }

  .add-tag-txt {
    color: base.$color-white;
    font-family: base.$primary-font-md;
    font-size: 20px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .no-tag-message {
    font: 20px base.$primary-font-rg;
    color: base.$color-darkgrey;
    background-color: base.$color-lightgrey;
    border-radius: 0.5rem;
    margin: 0.5rem 0.5rem 0 0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    min-height: 2.5rem;
  }  

  .tag-error {
    color: base.$color-error;
    font-family: base.$primary-font-rg;
    font-size: 14px;
  }
}

/* Mobile */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .search-bar-container {
    text-align: center;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .search-bar {
    font: 16px base.$primary-font-rg;
    margin-bottom: 1rem;
    width: 100%;
  }
}
