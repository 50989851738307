@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer), (max-width: variables.$max_width_medium_computer) {
  .custom-pagination-ctr {
    display: flex;
    justify-content: center;
  }

  .custom-pagination-item {
    color: base.$color-white;
    font-family: base.$primary-font-rg;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: base.$color-primary-green;

    &.dots,
    &.dots:hover {
      background-color: unset;
      color: base.$color-darkgrey;
      cursor: default;
    }
    &:hover {
      background-color: base.$color-primary-green;
      cursor: pointer;
    }
  }

  .total-page {
    color: base.$color-darkgrey;
    font-family: base.$primary-font-rg;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    border-radius: 50%;

    &.dots,
    &.dots:hover {
      background-color: unset;
      color: base.$color-darkgrey;
      cursor: default;
    }
    &:hover {
      background-color: base.$color-lightgrey;
      cursor: pointer;
    }
  }
}
