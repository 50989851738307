@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .dashboard-table-container {
    margin-top: 2rem;
  }

  .header-list {
    font-family: base.$primary-font-rg;
    font-size: 20px;
    font-weight: bold;
    color: base.$color-darkgrey;
    text-align: center;
  }

  .tab-cell-ctr {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    justify-content: center;
  }

  .table-item {
    font-family: base.$primary-font-rg;
    color: base.$color-lightgrey-2;
    font-weight: 600;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
  }

  .date-item {
    font-family: base.$primary-font-rg;
    color: base.$color-lightgrey-2;
    display: flex;
    justify-content: center;
  }

  .media-icon,
  .results-icon,
  .status-item {
    display: flex;
    justify-content: center;
  }

  .success-analysis,
  .running-analysis,
  .failed-analysis {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 150px;
    height: 40px;
  }

  .success-analysis {
    background-color: base.$color-primary-green;
  }

  .running-analysis {
    background-color: base.$color-yellow;
  }

  .failed-analysis {
    gap: 0.5rem;
    background-color: base.$color-error;
  }

  .value::first-letter {
    text-transform: uppercase;
  }

  .succeeded,
  .running,
  .failed {
    color: base.$color-white;
    text-transform: lowercase;
  }

  .delete-selected {
    cursor: pointer;
    margin-left: 0.5rem;
  }

  .clear-all {
    margin-left: 1rem;
    cursor: pointer;
  }

  .info {
    color: base.$color-white;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
  .dashboard-table-container {
    margin-left: 0;
    margin-top: 2rem;
  }

  .clear-all {
    margin-left: 0;
    margin-right: 1rem;
  }

  .refresh-icon {
    margin-left: 1rem;
    cursor: pointer;
  }

  .delete-selected {
    cursor: pointer;
    margin-left: 0.5rem;
  }
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
