@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .page-404-ctr {
    background: base.$color-white;
    border: 1px solid base.$color-lightgrey-border;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 5rem 10rem;
    padding: 2rem;
  }

  .not-found-ctr {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .page-404 {
    font-family: base.$primary-font-md;
    font-size: 48px;
  }

  .not-found-txt {
    font-family: base.$primary-font-rg;
    padding-bottom: 2rem;
  }

  .not-found-back-txt {
    font-family: base.$primary-font-md;
    font-size: 20px;
    color: base.$color-primary-green;
    &:hover {
      color: base.$color-darkgrey;
    }
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
  .page-404-ctr {
    margin: 5rem;
  }
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .page-404-ctr {
    padding: 2rem;
    margin: 1rem;
  }
}
