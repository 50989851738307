@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .auth-screen {
    @include mixin.auth-screen;
  }

  .auth-container {
    @include mixin.auth-container;
  }

  .auth-container form.login {
    margin: 0 auto;
    max-width: 320px;
  }

  .auth-logo {
    @include mixin.auth-logo;
  }

  .auth-logo-container {
    @include mixin.auth-logo-container;
  }

  .form-label {
    font: 20px base.$primary-font-rg;
  }

  #username,
  #password {
    @include mixin.input-form;
  }

  #username:focus,
  #password:focus {
    @include mixin.input-form-focus;
  }

  .auth-button {
    @include mixin.auth-button;
  }

  .auth-button:hover:enabled {
    @include mixin.auth-button-hover;
  }

  .auth-button:disabled {
    @include mixin.auth-button-disable;
  }

  .forgotten-password-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
  }

  .forgotten-password-text {
    font: 15px base.$primary-font-md;
    color: base.$color-primary-green;
  }

  .forgotten-password-text:hover {
    color: base.$color-darkgrey;
  }

  .login-error {
    color: base.$color-error;
    font-size: small;
    margin-bottom: 10px;
  }

  .login-footer {
    display: flex;
    margin-top: 2rem;
  }
}

/* Mobile */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .auth-container {
    padding: 2rem 1rem 2rem 1rem;
    margin: 3rem 1rem;
  }
}
