@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer), (max-width: variables.$max_width_medium_computer) {
  .dashboard-screen {
    @include mixin.page-screen;
  }

  .dashboard-container {
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
  }

  .filters-container {
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-bottom: 2rem;
  }

  .filters-btn-ctr,
  .icon-ctr {
    display: flex;
  }

  .dashboard-no-analyses {
    font: 20px base.$primary-font-rg;
    color: base.$color-darkgrey;
    margin: 2rem 1.5rem;
  }

  .offset-ctr {
    display: flex;
    flex: auto;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0 0 1rem;
  }

  .offset-txt {
    font-family: base.$primary-font-rg;
    margin-right: 0.5rem;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
  .dashboard-container {
    padding: 0;
  }

  .filters-container {
    flex-direction: column;
  }

  .offset-ctr {
    display: flex;
    flex: auto;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 0 0 0;
  }
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
