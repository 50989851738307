@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .page-screen {
    @include mixin.page-screen;
  }

  .title-container {
    @include mixin.page-title-container;
  }

  .title {
    @include mixin.page-title;
  }

  .bottom-border {
    @include mixin.border-title;
  }

  .td-mobile {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8rem;
  }

  .td-mobile-hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8rem;
    color: base.$color-error;
  }

  .td-text {
    color: base.$color-error;
  }

  .upload-container {
    padding: 30px;
    margin: auto;
  }

  .upload-title {
    font: 20px base.$primary-font-rg;
    margin-bottom: 2rem;
  }

  .upload-message {
    font-family: base.$primary-font-rg;
    margin-top: 2rem;
  }

  .dropzone {
    border-color: base.$color-primary-green;
    border-radius: 12px;
    border-style: dotted;
    padding: 20px;
    text-align: center;
    outline: none !important;
  }

  .dropzone-text {
    font-family: base.$primary-font-rg;
    font-weight: 900;
    padding-top: 20px;
    font-size: 16px;
    color: base.$color-dropzone-text;
  }

  .dropzone-span {
    font-family: base.$primary-font-rg;
    font-weight: 900;
    color: base.$color-primary-green;
    margin-left: 0;
  }

  .dropzone-text2 {
    font-family: base.$primary-font-rg;
    font-weight: 900;
    font-size: 16px;
    color: base.$color-dropzone-text;
  }

  .selected-files-text {
    font-family: base.$primary-font-rg;
    font-weight: bold;
    margin-top: 2rem;
  }

  .button-container {
    text-align: center;
  }

  .dropzone-err-msg {
    font-family: base.$primary-font-rg;
    font-size: 16px;
    color: base.$color-error;
    margin-top: 0.5rem;
  }

  .goBack-container {
    text-align: center;
  }

  .goBack-button {
    background-color: base.$color-primary-green !important;
    border-color: base.$color-primary-green !important;
    border-radius: 0.5rem !important;
    box-shadow: none !important;
    white-space: normal;
    min-width: 15% !important;
    margin-top: 30px !important;
  }

  .goBack-button:hover {
    background-color: base.$color-green-hover !important;
    border-color: base.$color-green-hover;
  }

  .goBack-button:disabled {
    color: base.$color-white !important;
    opacity: 0.65;
  }

  .reset-txt {
    font-family: base.$primary-font-md;
    font-size: 20px;
    color: base.$color-white;
  }

  .file-info {
    font-family: base.$primary-font-rg;
  }

  .table-header.upload {
    font: 18px base.$primary-font-rg;
    text-align: left;
  }

  .close-icon-container {
    max-width: 0.85rem;
    outline: none !important;
  }

  .close-icon-container-hidden {
    max-width: 0.85rem;
    outline: none !important;
    opacity: 0;
    pointer-events: none;
  }

  .table-close-icon {
    border-radius: 50%;
    color: base.$color-red;
    vertical-align: middle;
    max-width: 0.85rem;
  }

  .table-close-icon-tag {
    border-radius: 50%;
    color: base.$color-darkgrey;
    margin-bottom: 0.15rem;
    margin-right: 0.5rem;
    max-width: 0.85rem;
  }

  .selectTag-container {
    justify-content: left;
    margin-top: 2rem;
  }

  .info-container {
    font-family: base.$primary-font-rg;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .tag-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .selected-tag {
    background-color: base.$color-grey-tag;
    border-radius: 0.75rem;
    padding: 0.5rem;
    display: flex;
    text-align: center;
  }

  .selected-tag:hover {
    background-color: base.$color-lightgrey-hover;
  }

  .tag-name {
    font: 17px base.$primary-font-md;
    color: base.$color-darkgrey;
    margin: auto;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    vertical-align: center;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
  .td-mobile {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8rem;
  }

  .td-mobile-hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8rem;
    color: base.$color-error;
  }
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .container {
    padding: 0;
  }

  .selected-files-text {
    font-size: 18px;
  }

  .info-container {
    font-size: 18px;
  }

  .upload-container {
    padding: 1.5rem 0 1.5rem 0;
  }

  .file-mobile-view {
    display: none;
  }
}
