@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .action-ctr {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 1rem;
  }

  .disabled-icon {
    opacity: 0.3;
  }

  .table-ctr {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow-x: auto;
    width: 100%;
  }

  .table-ctr::-webkit-scrollbar {
    @include mixin.modal-scrollbar;
  }
  .table-ctr::-webkit-scrollbar-track {
    @include mixin.modal-scrollbar-track;
  }
  .table-ctr::-webkit-scrollbar-thumb {
    @include mixin.modal-scrollbar-thumb;
    &:hover {
      @include mixin.modal-scrollbar-thumb-hover;
    }
    &:active {
      @include mixin.modal-scrollbar-thumb-active;
    }
  }

  .table {
    margin-bottom: 0;
    border: solid 1px base.$color-lightgrey-border;
  }

  .table thead th {
    border-bottom: solid 1px base.$color-lightgrey-border;
  }

  .table thead th.table-header {
    vertical-align: middle;
  }

  .table-header {
    font-family: base.$secondary-font-b;
    font-size: 20px;
    color: base.$color-darkgrey;
    background-color: base.$color-lightgrey;
  }

  .table-header-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .table-row {
    border-bottom: 0.2px solid base.$color-table-header-bg;
  }

  .table-row:last-child {
    border-bottom: unset;
  }

  .table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 16rem;
    vertical-align: middle !important;
  }

  .cells-checkbox {
    color: base.$color-lightgrey-2 !important;
  }

  .checked-rows {
    color: #43a047 !important;
  }

  .noRes-found {
    font: 20px base.$primary-font-rg;
    color: base.$color-darkgrey;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-table-ctr {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    &::before {
      flex: 1;
      content: '';
    }
  }

  .footer-tbl-pagination {
    display: flex;
    justify-content: center;
  }

  .option-footer-table-ctr {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    gap: 0.5rem;
  }

  .csv-icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: base.$color-darkgrey !important;
    &:hover {
      background-color: base.$color-lightgrey-hover !important;
    }
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .footer-table-ctr {
    flex-direction: column;
  }

  .option-footer-table-ctr {
    justify-content: center;
    width: 100%;
  }
}
