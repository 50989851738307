@use '../scss/base';
@use '../scss/mixin';
@use '../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .activity-btn {
    font: 17px base.$primary-font-md !important;
    border-radius: 13px !important;
    border-color: base.$color-grey-tag !important;
    background-color: base.$color-grey-tag !important;
    box-shadow: none !important;
    margin-right: 1rem;
    &:hover {
      background-color: base.$color-lightgrey-hover !important;
      border-color: base.$color-lightgrey-hover !important;
      box-shadow: none !important;
    }
  }

  .activity-filtered-btn {
    font: 17px base.$primary-font-md !important;
    color: base.$color-white !important;
    border-radius: 13px !important;
    border-color: base.$color-primary-green !important;
    background-color: base.$color-primary-green !important;
    box-shadow: none !important;
    margin-right: 1rem;
    &:hover {
      background-color: base.$color-green-hover !important;
      border-color: base.$color-green-hover !important;
      box-shadow: none !important;
    }
  }

  .activity-filter-label {
    color: base.$color-darkgrey;
    white-space: nowrap;
  }

  .activity-filtered-label {
    color: base.$color-white;
    white-space: nowrap;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.validate-btn {
    font: 17px base.$primary-font-md;
    color: base.$color-white;
    border-radius: 13px;
    border-color: base.$color-primary-green;
    background-color: base.$color-primary-green;
    box-shadow: none;
    &:hover {
      background-color: base.$color-green-hover;
      border-color: base.$color-green-hover;
      box-shadow: none;
    }
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.disabled-btn {
    font: 17px base.$primary-font-md;
    border-radius: 13px;
    color: base.$color-white;
    border-color: base.$color-primary-green;
    background-color: base.$color-primary-green;
    box-shadow: none;
    opacity: 0.65;
  }

  .selected-tag {
    font: 20px base.$primary-font-rg;
    color: base.$color-darkgrey;
    background-color: base.$color-lightgrey-hover;
    border-radius: 0.5rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    min-height: 2.5rem;
    outline: none !important;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
