@use '../../scss/base';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .dropdown-ctr {
    position: absolute;
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: base.$color-white;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }

  .dropdown-title {
    font-family: base.$primary-font-rg;
    font-size: 16px;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
  }

  .dropdown-list-element {
    padding: 0 !important;
  }

  .dropdown-element {
    font-family: base.$primary-font-rg;
    color: base.$color-darkgrey;
    padding: 0.5rem 1.5rem;
    display: block;
    width: 100%;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .disabled-el {
    font-family: base.$primary-font-rg;
    color: base.$color-lightgrey-2;
    padding: 0.5rem 1.5rem;
    display: block;
    width: 100%;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
