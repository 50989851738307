@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer), (max-width: variables.$max_width_medium_computer) {
  .date-picker {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 1rem;
  }

  .css-3eghsz-PrivatePickersYear-button.Mui-selected {
    background-color: base.$color-primary-green !important;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .date-picker {
    margin-right: unset !important;
    flex-direction: column;
    gap: 0;
  }
}
