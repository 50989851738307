@use '../../scss/base';
@use '../../scss/variables';

.modal_content {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  gap: 1rem;
}
.icon_info{
  display: flex;
  padding: 0.5rem 0;
}
.error_detail {
  font-family: base.$primary-font-rg;
  color: base.$color-lightgrey-2;
  overflow-wrap: anywhere;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 100%;
    background-color: base.$color-error;
    border-radius: 10px 0 0 10px;
  }
}
