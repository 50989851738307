@use '../../scss/base';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .item-filters {
    font-family: base.$primary-font-rg;
    font-size: 20px;
    display: flex;
    align-items: center;
    background-color: base.$color-lightgrey;
    border-radius: 0.5rem;
    flex-wrap: wrap;
    margin: 0 0.5rem 0.5rem 0.5rem;
    padding: 0.25rem 1rem;
  }
}
