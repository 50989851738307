@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer), (max-width: variables.$max_width_medium_computer) {
  .auth-screen {
    @include mixin.auth-screen;
  }

  .auth-container {
    @include mixin.auth-container;
  }

  .auth-container form {
    margin: 0 auto;
    max-width: 380px;
  }

  .auth-logo {
    @include mixin.auth-logo;
  }

  .auth-logo-container {
    @include mixin.auth-logo-container
  }

  .confirm-title-container {
    text-align: center;
  }

  .confirm-title {
    font: 23px base.$primary-font-md;
    margin-top: 2rem;
  }

  .confirm-text-container {
    text-align: center;
    margin-top: 2rem;
  }

  .confirm-text {
    font: 13.25px base.$primary-font-md;
    margin-bottom: 1.5rem;
  }

  #code,
  #usernameConfirm,
  #newPassword,
  #confirmNewPassword {
    @include mixin.input-form;
  }

  #code:focus,
  #newPassword:focus,
  #confirmNewPassword:focus {
    @include mixin.input-form-focus;
  }

  .auth-button {
    @include mixin.auth-button;
  }

  .auth-button:hover:enabled {
    @include mixin.auth-button-hover;
  }

  .go-back-container {
    @include mixin.go-back-container;
  }

  .go-back-text {
    @include mixin.go-back-text;
  }

  .go-back-text:hover {
    @include mixin.go-back-text-hover;
  }

  .input-error {
    @include mixin.input-error;
  }
}

/* Mobile */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .auth-container {
    padding: 2rem 1rem 2rem 1rem;
    margin: 3rem 1rem;
  }
}
