@use '../../scss/base';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .input_ctr {
    display: flex;
    width: 100%;
    font-family: base.$primary-font-md;
    font-size: 17px;
  }

  .edit_subtitle {
    color: base.$color-darkgrey;
    font-family: base.$primary-font-rg;
    font-size: 20px;
    text-align: center;
    padding-bottom: 1rem;
  }

  .existing_tag {
    color: base.$color-error;
    font-family: base.$primary-font-rg;
    font-size: 14px;
  }

  .cancel_btn {
    color: base.$color-white !important;
    font-family: base.$primary-font-md !important;
    font-size: 17px !important;
    border-radius: 0.75rem !important;
    border-color: base.$color-lightgrey-2 !important;
    background-color: base.$color-lightgrey-2 !important;
    box-shadow: none !important;
    &:hover {
      background-color: base.$color-lightgrey-hover !important;
      border-color: base.$color-lightgrey-hover !important;
    }
  }

  .edit_btn {
    color: base.$color-white !important;
    font-family: base.$primary-font-md !important;
    font-size: 17px !important;
    border-radius: 0.75rem !important;
    border-color: base.$color-green-tag !important;
    background-color: base.$color-green-tag !important;
    box-shadow: none !important;
    &:hover {
      border-color: base.$color-green-hover !important;
      background-color: base.$color-green-hover !important;
    }
  }

  .delete_icon {
    border-radius: 50%;
    color: base.$color-red;
    height: 20px;
    width: 20px;
  }

  .no_tag_message {
    display: flex;
    justify-content: center;
    padding: 2rem;
    font-family: base.$primary-font-rg;
    font-size: 18px;
  }
}
