@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$max_width_mobile),
  (max-width: variables.$max_width_medium_computer) {
  .profile-screen {
    background: base.$color-white;
    border: 1px solid base.$color-lightgrey-border;
    border-radius: 0.5rem;
    width: 80%;
    margin: auto;
    margin-top: 2rem;
    padding: 1rem;
  }

  .profile-title-container {
    margin-left: 1.5rem;
  }

  .profile-title {
    font: 30px base.$secondary-font-sb;
    color: base.$color-darkgrey;
    margin-top: 1.5rem;
  }

  .bottom-border {
    @include mixin.border-title;
  }

  .input-container {
    padding: 1rem;
  }

  .input-container ul li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-container ul li span {
    font-family: base.$primary-font-rg;
    font-size: 20px;
    flex-basis: 35%;
    padding: 1rem;
  }

  .span-label {
    font-weight: bold;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_mobile) {
  .profile-list {
    padding-left: 0;
  }

  .input-container ul li {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .input-container ul li span {
    flex-basis: unset;
  }

  .span-label {
    min-width: 40%;
  }

  .span-info {
    min-width: 50%;
  }

  .dropdown {
    display: none;
  }
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
  .profile-list {
    padding-left: 0;
  }

  .input-container ul li span {
    font-size: 18px;
    padding: 0 0 1rem;
  }

  .span-info {
    min-width: 100%;
    margin-bottom: 1rem;
    text-align: center;
  }

  .span-label {
    min-width: 100%;
    text-align: center;
  }

  .dropdown {
    display: none;
  }
}
