@use '../../scss/base';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .modalEdit-product {
    font-family: base.$primary-font-rg;
    color: base.$color-darkgrey;
    margin-bottom: 1rem;
  }

  .edit-item-filters {
    font-family: base.$primary-font-rg;
    font-size: 20px;
    display: flex;
    align-items: center;
    background-color: base.$color-lightgrey;
    border-radius: 0.5rem;
    flex-wrap: wrap;
    margin: 0 0.5rem 0.5rem 0.5rem;
    padding: 0.25rem 1rem;
    font-family: base.$primary-font-rg;
    font-size: 20px;
    color: base.$color-darkgrey;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    text-align: inherit;
    white-space: nowrap;
    border: 0;
    width: 98%;
    &:hover {
      background-color: base.$color-lightgrey-hover;
    }
  }

  .edit-tag {
    font: 17px base.$primary-font-md !important;
    color: base.$color-white !important;
    border-radius: 0.75rem !important;
    border-color: base.$color-green-tag !important;
    background-color: base.$color-green-tag !important;
    box-shadow: none !important;
    &:hover {
      background-color: base.$color-green-hover !important;
      border-color: base.$color-green-hover !important;
    }
  }

  .edited-tag {
    margin-left: 0.5rem;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .char_limit {
    font-family: base.$primary-font-rg;
    font-size: 14px;
    color: base.$color-error;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
