@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_computer),
  (max-width: variables.$max_width_medium_computer) {
  .table-privacy-ctr {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow-x: auto;
  }

  .privacy-table {
    display: table;
    width: 100%;
    table-layout: fixed;
    & td {
      white-space: pre-wrap;
    }
  }

  .privacy-header,
  .privacy-table-body {
    border: solid 1px base.$color-lightgrey-border;
    padding: 1rem;
    display: table-cell;
    min-width: 15rem;
  }

  .privacy-header {
    font-family: base.$secondary-font-b;
    font-size: 20px;
    color: base.$color-darkgrey;
    background-color: base.$color-lightgrey;
    text-align: center;
  }

  .privacy-table-body {
    font-family: base.$primary-font-rg;
    font-size: 18px;
  }
}

/* medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {

}

/* small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
