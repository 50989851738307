@use '../../scss/base';
@use '../../scss/mixin';
@use '../../scss/variables';

@media only screen and (min-width: variables.$min_width_medium_computer),
  (max-width: variables.$max_width_medium_computer) {
  .app-modal {
    max-height: 300px;
    overflow: auto;
    margin: 0 1rem 1rem 1rem;
  }

  .app-modal::-webkit-scrollbar {
    @include mixin.modal-scrollbar;
  }
  .app-modal::-webkit-scrollbar-track {
    @include mixin.modal-scrollbar-track;
  }
  .app-modal::-webkit-scrollbar-thumb {
    @include mixin.modal-scrollbar-thumb;
    &:hover {
      @include mixin.modal-scrollbar-thumb-hover;
    }
    &:active {
      @include mixin.modal-scrollbar-thumb-active;
    }
  }

  .list-item {
    margin: 0 0.5rem;
  }

  .MuiButton-label {
    text-transform: none;
  }
}

/* Medium */
@media only screen and (max-width: variables.$max_width_medium_phone) {
}

/* Small */
@media only screen and (max-width: variables.$max_width_small_phone) {
}
