@use '../scss/base';
@use '../scss/mixin';
@use '../scss/variables';

@media only screen and (min-width: variables.$max_width_mobile), (max-width: variables.$max_width_medium_computer) {
  .select-app {
    width: 80%;
    margin: auto;
    margin-top: 2rem;
  }

  .title {
    @include mixin.page-title;
  }

  .bottom-border {
    @include mixin.border-title;
  }

  .app-container {
    min-height: 300px;
    width: 100%;
    margin: auto;
    margin-top: 2rem;    
  }

  .no-app {
    font: 20px base.$primary-font-rg;
    color: base.$color-darkgrey;
  }
}

@media only screen and (min-width: 600px), (max-width: 767px) {
  .app-container {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: variables.$max_width_small_phone) {
  .app-container {
    justify-content: center;
  }

  .application-block {
    margin-right: 0;
    margin-top: 10px;
    max-height: 155px;
    text-align: center;
  }

  .application-title {
    font-size: 27px;
  }
}
